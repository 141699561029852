/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import './DailyDollar.scss';
import { AgroLink } from 'agrofy-ui-components';
import CortevaLogo from '../Icons/CortevaLogo';
import AgrofyPayLogo from '../Icons/AgrofyPayLogo';

export const DailyDollar = ({ dollarData, DollarShowSponsoredBy }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const loader = (
    <div className="loader container pr-0" style={{ minHeight: '90px' }}>
      <div className="animated-background" />
    </div>
  );

  const style = (variationVal) =>
    variationVal > 0 ? 'up arrow' : variationVal < 0 ? 'down arrow' : 'equal';

  const format = (val) => val && val.toString().replace('.', ',');

  const formatNumber = (val, indicatorID) => {
    if (val && indicatorID === 3) {
      const numericValue = parseFloat(val);
      const formattedValue = numericValue >= 10000 ? Math.floor(numericValue) : numericValue.toFixed(2);
      return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      return val && val.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  };

  const value = (item) => {
    if (item.id === 3) { // Merval
      return item.buy && formatNumber(item.buy, item.id);
    } else {
      return item.id === 2 ? item.buy && formatNumber(item.buy, item.id) : item.sell && formatNumber(item.sell, item.id) || item.buy && formatNumber(item.buy, item.id);
    }
  };

  const detail = (indicatorID) =>
    indicatorID === 2 ? 'COMPRA' : indicatorID === 35 ? 'VENTA' : '';

  const cssClass = (indicatorID) =>
    indicatorID === 2 ? 'compra' : indicatorID === 35 ? 'venta' : 'merval';

  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackEvent',
        eventCategory: 'Navigation',
        eventAction: 'Agrofy News - Indicadores Home',
        eventLabel: 'Bolsas y Monedas ',
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    history.push({
      pathname: '/economia-politica/dolar',
      state: 'dolar',
    });
  };

  return (
    <Link
      to={{
        pathname: '/economia-politica/dolar',
        state: 'dolar',
      }}
      onClick={manageGTM}
    >
      {!dollarData ? (
        loader
      ) : (
        <React.Fragment>
          <div className="component daily-news arrow dolar">
          {DollarShowSponsoredBy && (
            <div className="sponsored-by">
              <span className="sponsored-by-text">{t('sponsored-by')}</span>
              <AgrofyPayLogo height="16px" width="95px" />
            </div>
          )}
          <div className={`title-container ${!DollarShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              <span className="title">{t('stocks and currencies')}</span>
              <AgroLink size="lg">{t('view_more')}</AgroLink>
            </div>
            <div className={`daily-item ${!DollarShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              {dollarData.map((item, idx) => (
                <div
                  key={idx}
                  className={`${cssClass(item.id)} ${style(item.variation)}`}
                >
                  {item.id !== 3 ? (
                    <label className="name">
                      {`${item.name} `}
                      <label className="detail">({detail(item.id)})</label>
                      <label className="detail-mob">({detail(item.id)})</label>
                    </label>
                  ) : (
                    <label className="name">{item.name}</label>
                  )}
                  <div className="values-percents">
                    <label className="price">
                      $ {value(item)}
                    </label>
                    <div>
                      <label className="percent">{`${format(
                        item.variation
                      )} %`}</label>
                      <label className="indicator">
                        <label className="icon"></label>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Link>
  );
};

export default DailyDollar;