import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import { AgroLink } from "agrofy-ui-components";
import AgrofyPayLogo from '../Icons/AgrofyPayLogo';

import "../DailyDollar/DailyDollar.scss";

export const DailyAgricultura = ({quotesData, agriculturaShowSponsoredBy}) => {
  const { t } = useTranslation();
  const loader = (
    <div className="loader container pr-0" style={{ minHeight: "90px" }}>
      <div className="animated-background" />
    </div>
  );
  const style = (variationVal) =>
    variationVal > 0 ? "up arrow" : variationVal < 0 ? "down arrow" : "equal";
  const format = (val) => val && val.toString().replace(".", ",");
  const value = (item) => format(item.Quote);
  const formatNumber = (val) =>
    val && val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const manageGTM = () => {
    const tagManagerArgs = {
      dataLayer: {
        event: "trackEvent",
        eventCategory: "Navigation",
        eventAction: "Agrofy News - Indicadores Home",
        eventLabel: "Bolsas y Monedas ",
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return (
    <Link
      to={{
        pathname: "/cotacoes",
        state: "Sumário",
      }}
    >
      {!quotesData ? (
        loader
      ) : (
        <React.Fragment>
          <div className="component daily-news arrow dolar ml-0">
          {agriculturaShowSponsoredBy && (
            <div className="sponsored-by">
              <span className="sponsored-by-text">{t('sponsored-by')}</span>
              <img alt="BYD" src="https://news.agrofystatic.com/auspicio-byd.png?d=73x14" width={73} height={14}/>
            </div>
          )}
          <div className={`title-container ${!agriculturaShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              <span className="title">{t("quote-first-title")}</span>
              <AgroLink size="lg">{t("view_more")}</AgroLink>
            </div>
            <div className={`daily-item ${!agriculturaShowSponsoredBy ? 'hide-sponsored' : ''}`}>
              {quotesData[0].HomePriceTabs[0].HomePrices.map(
                (item, idx) => (
                  <ListContainer
                    key={idx}
                    className={` ${style(item.PercentualVariation)}`}
                  >
                    <label className="name">{item.ProductDescription}</label>

                    <div className="values-percents">
                      <label className="price">
                        R$ {value(item) && formatNumber(value(item))}
                      </label>

                      <div>
                        <label className="percent">{`${format(
                          item.PercentualVariation
                        )} %`}</label>
                        <label className="indicator">
                          <label className="icon"></label>
                        </label>
                      </div>
                    </div>
                  </ListContainer>
                )
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </Link>
  );
};

export default DailyAgricultura;

const ListContainer = styled.div`
  padding: 0 16px 4px;
`;
