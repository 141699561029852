import React from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../utils/helpers";
import TagManager from "react-gtm-module";
import { AgroLink, Box } from "agrofy-ui-components";
import "./QuoteTabs.scss";

export const QuoteTabs = ({ quotesData, quotesShowSponsoredBy }) => {
  const loader = (
    <div className="loader container pr-0" style={{ minHeight: "90px" }}>
      <div className="animated-background" />
    </div>
  );
  const { t } = useTranslation();

  const gtmTrack = (tabName) => {
    const tagManagerArgs = {
      dataLayer: {
        event: "select_content",
        content_type: "grain_price",
        item_id: tabName.toLowerCase(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const gtmTrackViewMore = event => {
    event.stopPropagation();

    const tagManagerArgs = {
      dataLayer: {
        event: "trackEvent",
        eventCategory: "Navigation",
        eventAction: "Agrofy News - Indicadores Home",
        eventLabel: "Cotizaciones granos"
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const hasData = !isEmpty(quotesData);

  const addAccent = (str) => (str.includes("Maiz") ? "Maíz" : str);

  const handleSelect = (tabName, event) => {
    event.stopPropagation();

    gtmTrack(addAccent(tabName));
  };

  const getCurrency = (productDescription) => {
    return productDescription.toLowerCase().includes("pizarra") ? "$" : "U$S";
  }

  const formatNumber = (val) => {
    return val && val.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }    

  const getTabs = () => {
    return (
      <>
        {quotesShowSponsoredBy && (
          <div className="sponsored-by">
            <span className="sponsored-by-text">{t('sponsored-by')}</span>
            <img alt="fyo" src="https://news.agrofystatic.com/patrocinado-logo-fyo.jpg?d=34x16" width={35} height={16}/>
          </div>
        )}
        <Tabs onSelect={(tabName, event) => handleSelect(tabName, event)}>
          {hasData
            ? quotesData.HomePriceTabs.map((item, index) => (
              <Tab
                transition={false}
                eventKey={item.TabName}
                title={addAccent(item.TabName)}
                key={index}
              >
                {item.HomePrices.map((subItem, subIndex) => (
                  // we should use subItem.IndicadorText when the attribut exist.
                  // eslint-disable-next-line no-nested-ternary
                  <>
                    <div
                      key={subIndex}
                      className={`${subItem.PercentualVariation > 0
                          ? "up arrow"
                          : subItem.PercentualVariation === 0
                            ? "equal"
                            : "down arrow"
                        } tab-item`}
                    >
                      <div>
                        <label className="name">{subItem.ProductDescription}</label>
                        <Box className="priceContainer">
                          <label className="price ">
                            {getCurrency(subItem.ProductDescription)} {formatNumber(subItem.Quote)}
                          </label>
                          <div>
                            <label className="percent">
                              {subItem.PercentualVariation.toString().replace(".", ",")}%
                            </label>
                            <label className="indicator">
                              <label className="icon"></label>
                            </label>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </>
                ))}
              </Tab>
            ))
            : null}
        </Tabs>
      </>
    );
  };

  return hasData ? (
    <Link to="/granos" onClick={gtmTrackViewMore}>
      <div className={`cotization-tabs gray-tabs ${quotesShowSponsoredBy ? 'show-sponsored' : ''}`} >
        {getTabs()}
        <AgroLink size="lg" className={`card-view-more ${quotesShowSponsoredBy ? 'show-sponsored' : ''}`}>
          {t("view_more")}
        </AgroLink>
      </div>
    </Link>
  ) : (
    loader
  );
};

export default QuoteTabs;
